import React from "react";
import { graphql } from "gatsby";

import { useWindowSize } from "../../lib/useWindowSize";
import { screenSizes } from "../../styles/variables";

import SEO from "../../components/seo";
import {
  MixdownWrapper,
  LeftWrapper,
  MixdownTitle,
  SmallText,
} from "../../StyledComponents/mixdown.styles";
import MixdownThanksMobile from "../../components/mixdownMobilePages/ThanksMobile";

const MixdownThanks = ({ data }) => {
  const { width } = useWindowSize();

  const isBelowTablet = width <= screenSizes.tablet;

  const { title, thanksDescription } =
    data.allFile.edges[0].node.childMarkdownRemark.frontmatter;

  return (
    <>
      {!isBelowTablet ? (
        <>
          <SEO title="SPANDAU20 Mixdown" />
          <MixdownWrapper>
            <LeftWrapper>
              <MixdownTitle>{title}</MixdownTitle>
              <SmallText>{thanksDescription}</SmallText>
            </LeftWrapper>
          </MixdownWrapper>
        </>
      ) : (
        <MixdownThanksMobile data={data} />
      )}
    </>
  );
};

export default MixdownThanks;

export const query = graphql`
  query ThanksQuery {
    allFile(filter: { sourceInstanceName: { eq: "mixdownThankYou" } }) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              title
              thanksDescription
            }
          }
        }
      }
    }
  }
`;
