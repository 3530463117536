import React from "react";
import SEO from "../seo";
import {
  MixdownWrapper,
  LeftWrapper,
  MixdownTitle,
  SmallText,
} from "../../StyledComponents/mixdown.styles";

const MixdownThanksMobile = ({ data }) => {
  const { title, thanksDescription } =
    data.allFile.edges[0].node.childMarkdownRemark.frontmatter;

  return (
    <>
      <SEO title="SPANDAU20 Mixdown" />
      <MixdownWrapper style={{ marginTop: "48px" }}>
        <LeftWrapper gap="24px">
          <MixdownTitle>{title}</MixdownTitle>
          <SmallText>{thanksDescription}</SmallText>
        </LeftWrapper>
      </MixdownWrapper>
    </>
  );
};

export default MixdownThanksMobile;
